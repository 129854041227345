module.exports = {
  siteTitle: 'RILEY J. EWING', // <title>
  manifestName: 'Grayscale',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-grayscale/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'RILEY J. EWING',
  subHeading: 'Actor, Singer, Filmmaker, and all around Goon  ',

  // social
  socialLinks: [
    {
      icon: 'fa-imdb',
      name: 'IMDB',
      url: 'https://www.imdb.com/name/nm8818281/',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/riley-j-ewing-a36798154/',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://www.facebook.com/RileyJacobEwing',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/rile.e_coyote/?hl=en',
    },
  ],
  email: 'riley.jacob.ewing@gmail.com ',
  phone: '913-302-3443',
  address: 'Brooklyn, NY',
};
